/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License. 
 */

import CategoricalScheme from '../../CategoricalScheme';

const schemes = [
  {
    id: 'oasisColors',
    label: 'Sunset Oasis',
    colors: [
      '#FFABAB',
      '#9CE1A1',
      '#9FC0FF',
      '#FFDE9D',
      '#8CEAF0',
      '#FFAFD6',
      '#FFBA89',
      '#BFB2EA',
      '#ACE4FD',
      '#DDA5E6',
      '#9AA5D0',
      '#E2E296',
    ],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
